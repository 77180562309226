<template>
    <div>
        <div v-if="blog.id">
            <transition name="fade">
                <div v-if="step === 'target'">
                    <b-row class="justify-content-center">
                        <b-col
                            cols="12"
                            sm="8"
                            md="7"
                            xl="5"
                        >
                            <b-overlay
                                :show="is_loading"
                            >
                                <b-card
                                    no-body
                                >
                                    <b-card-header class="justify-content-center">
                                        <b-card-title>
                                            Site WordPress à extraire
                                        </b-card-title>
                                    </b-card-header>
                                    <b-card-body>
                                        <b-form-group
                                            label="Domaine du WordPress"
                                            description="Le domaine ici correspondant au domaine du site vers lequel vous souhaitez extraire un article"
                                        >
                                            <b-input-group
                                                prepend="https://"
                                            >
                                                <b-form-input
                                                    v-model="target.domain"
                                                    placeholder="exemple.com"
                                                    @keyup.enter="testEligibility"
                                                />
                                            </b-input-group>
                                        </b-form-group>
                                        <transition name="fade">
                                            <div v-if="target.err" class="alert alert-danger">
                                                <div class="alert-body">
                                                    {{ target.err }}
                                                </div>
                                            </div>
                                        </transition>
                                        <div class="d-flex justify-content-center">
                                            <b-button
                                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                variant="primary"
                                                @click="testEligibility"
                                            >
                                                Tester l'égibilité et continuer
                                            </b-button>
                                        </div>
                                    </b-card-body>
                                </b-card>
                            </b-overlay>
                        </b-col>
                        <b-col
                            cols="12"
                            md="6"
                            xl="4"
                            class="d-none"
                        >
                            <b-card>
                                En développement
                            </b-card>
                        </b-col>
                    </b-row>
                </div>
            </transition>
            <transition name="fade">
                <div v-if="step === 'choice'">
                    <b-row class="justify-content-center">
                        <b-col
                            cols="12"
                            md="7"
                            xl="6"
                        >
                            <b-card
                                no-body
                            >
                                <b-card-header class="justify-content-center">
                                    <b-card-title>
                                        <b-card-title>
                                            Identification de l'article
                                        </b-card-title>
                                    </b-card-title>
                                </b-card-header>
                                <b-card-body>
                                    <b-card-text>
                                        Nous utilisons le slug de l'article pour le retrouver parmi tous les articles d'un wordpress.
                                        Pour trouver un slug il vous faut regarder dans l'url de l'article, une suite de mot représentant le titre de l'article
                                         souvent délimité par des tirets.
                                        Exemple: <br>
                                        <code>https://unsitewordpress.fr/index.php/2019/04/20/une-suite-de-mot-representant-le-titre</code><br>
                                        Le slug ici sera :<br> <code>une-suite-de-mot-representant-le-titre</code>
                                    </b-card-text>
                                    <b-form-group
                                        label="Slug de l'article"
                                        description="Pour sélectionner un article parmi les 20 derniers articles, laissez le slug vide"
                                    >
                                        <b-form-input
                                            v-model="target.slug"
                                            placeholder="Slug de l'article"
                                        />
                                    </b-form-group>
                                    <b-form-group
                                        label="Langue de départ de l'article"
                                    >
                                        <v-select
                                            v-model="target.fromLanguage"
                                            label="name"
                                            :clearable="false"
                                            :options="[{ name: 'Détecter la langue', key: 'auto' }]"
                                            :reduce="op => op.key"
                                        />
                                    </b-form-group>
                                    <b-form-group
                                        label="Langue d'arrivé de l'article"
                                    >
                                        <v-select
                                            v-model="target.toLanguage"
                                            label="name"
                                            :clearable="false"
                                            :options="[{ name: 'Français', key: 'fr', }, { name: 'Anglais', key: 'en', }, { name: 'Allemand', key: 'de', },]"
                                            :reduce="op => op.key"
                                        />
                                    </b-form-group>
                                    <div class="d-flex justify-content-md-between">
                                        <b-button
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            variant="outline-secondary"
                                            @click="cancelStep('target')"
                                        >
                                            Annuler
                                        </b-button>
                                        <b-button
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            variant="primary"
                                            @click="toRetrieveArticle"
                                        >
                                            Suivant
                                        </b-button>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col
                            cols="12"
                            md="6"
                            xl="4"
                            class="d-none"
                        >
                            <b-card>
                                En développement
                            </b-card>
                        </b-col>
                    </b-row>
                </div>
            </transition>
            <transition name="fade">
                <div v-if="step === 'retrieve-settings'">
                    <b-overlay
                        :show="is_loading || is_long_loading"
                    >
                        <b-row>
                            <b-col
                                cols="12"
                                class="d-flex justify-content-center"
                            >
                                <h3>
                                    Choix de l'article
                                </h3>
                            </b-col>
                            <b-col
                                v-for="post in target.possiblePosts"
                                :key="post.id"
                                md="6"
                                lg="4"
                            >
                                <b-card
                                    :title="post.title.rendered"
                                    :border-variant="post.id === target.post.id ? 'primary' : ''"
                                    @click="selectPost(post)"
                                >
                                    <b-button
                                        variant="primary"
                                    >
                                        Sélectionner
                                    </b-button>
                                </b-card>
                            </b-col>
                            <b-col
                                v-if="target.postsErr"
                                cols="12"
                            >
                                <div class="alert alert-danger">
                                    <div class="alert-body">
                                        {{ target.postsErr }}
                                    </div>
                                </div>
                            </b-col>
                            <b-col
                                cols="12"
                                class="d-flex justify-content-between"
                            >
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="outline-secondary"
                                    @click="cancelStep('choice')"
                                >
                                    Annuler
                                </b-button>
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                    :disabled="!target.post.id"
                                    @click="extractPost"
                                >
                                    Extraire l'article
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </div>
            </transition>
            <transition name="fade">
                <div v-if="step === 'editor'">
                    <b-overlay
                        :show="is_loading || is_long_loading"
                    >
                        <b-row>
                            <b-col
                                cols="12"
                                class="d-flex justify-content-center"
                            >
                                <h3>
                                    Réécriture de l'article
                                </h3>
                            </b-col>
                            <b-col cols="12" md="8">
                                <b-form-group
                                    label="Titre de l'article"
                                >
                                    <b-form-input
                                        v-model="finalPost.title"
                                        placeholder="Titre de l'article"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4">
                                <b-form-group
                                    label="Status de l'article"
                                >
                                    <v-select
                                        v-model="finalPost.status"
                                        label="name"
                                        :options="[{ key: 'publish', name: 'Publique', }, { key: 'private', name: 'Privé', }]"
                                        :reduce="a => a.key"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4">
                                <b-form-group
                                    label="Catégories de l'article"
                                >
                                    <v-select
                                        v-model="finalPost.categories"
                                        label="name"
                                        :options="finalPost.possible_categories"
                                        :reduce="a => a.name"
                                        multiple
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4">
                                <b-form-group
                                    label="Tags de l'article"
                                >
                                    <v-select
                                        v-model="finalPost.tags"
                                        label="name"
                                        :options="finalPost.possible_tags"
                                        :reduce="a => a.name"
                                        multiple
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4">
                                <b-form-group
                                    label="Format de l'article"
                                >
                                    <v-select
                                        v-model="finalPost.formats"
                                        label="name"
                                        :options="finalPost.possible_formats"
                                        :reduce="a => a.name"
                                        multiple
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4">
                                <b-form-group
                                    label="Mot(s) clé des éléments à ajouter"
                                >
                                    <b-form-input
                                        v-model="finalPost.keyWords"
                                        placeholder="Mot(s) clé des éléments à ajouter"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4">
                                <b-form-group
                                    label="Type des éléments à ajouter"
                                >
                                    <v-select
                                        v-model="finalPost.elementsType"
                                        label="name"
                                        :options="finalPost.possibleElementsType"
                                        :reduce="a => a.key"
                                        multiple
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                class="d-flex justify-content-between mt-50"
                            >
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="outline-secondary"
                                    @click="cancelStep('retrieve-settings')"
                                >
                                    Annuler
                                </b-button>
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                    @click="addPost"
                                >
                                    Ajouter l'article
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>

import {
    BCard,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BCardBody,
    BCardText,
    // BLink,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BOverlay,
    // BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { auth, database as db } from '@/firebase.config'

export default {
    components: {
        BCard,
        BCardHeader,
        BCardTitle,
        BRow,
        BCol,
        BCardBody,
        BCardText,
        // BLink,
        BButton,
		BFormGroup,
		BFormInput,
		BInputGroup,
        BOverlay,
        vSelect,
        // BForm,
    },
    directives: {
        Ripple,
	},
    data() {
        return {
            is_loading: true,
            is_long_loading: false,
            step: 'target',
            blog: {},
            target: {
                domain: '',
                slug: '',
                fromLanguage: 'auto',
                toLanguage: 'fr',
                err: '',
                isEligible: false,
                possiblePosts: [],
                postsErr: '',
                post: {},
            },
            editorOptions: {
                theme: 'snow',
            },
            finalPost: {
                title: '',
                content: '',
                status: 'publish',
                possible_categories: [],
                categories: [],
                possible_tags: [],
                tags: [],
                possible_formats: [],
                formats: [],
                keyWords: '',
                elementsType: ['images'],
                possibleElementsType: [{ key: 'images', name: 'Images' }, { key: 'tweet', name: 'Tweet' }, { key: 'instagram', name: 'Posts Instagram' }, { key: 'youtube', name: 'Vidéos Youtube' }],
            },
            // apiUrl: 'http://127.0.0.1:8001',
            // apiUrl: 'https://backend-blogily.contana.fr',
        }
    },
    watch: {
        // eslint-disable-next-line
        'target.domain': function () {
            this.target.err = ''
            this.target.postsErr = ''
        },
        // eslint-disable-next-line
        'target.slug': function () {
            this.target.err = ''
            this.target.postsErr = ''
        },
    },
    created() {
        console.log(this)
        db.collection('blogs').doc(this.$route.params.id).get()
        .then(doc => {
            if (doc.exists) {
                const blog = doc.data()
                blog.id = doc.id
                this.blog = blog
            } else {
                this.notify('Ce blog n\'existe pas', 'danger')
                this.$router.push({ name: 'blogs' })
            }
        })
        .catch(err => {
            console.log(err)
            this.notify('Ce blog n\'existe pas', 'danger')
            this.$router.push({ name: 'blogs' })
        })
        .finally(() => {
            this.is_loading = false
        })
    },
    methods: {
        testEligibility() {
            //
            // Appel API
            //
            this.is_loading = true
            auth.currentUser.getIdToken(/* forceRefresh */ true)
            .then(idToken => {
                this.$http.post(`${this.apiUrl}/wordpress/verify`, {
                    domain: this.target.domain,
                }, {
                    headers: {
                        Token: idToken,
                    },
                })
                .then(response => {
                    if (response.data.is_wordpress_eligible) {
                        this.target.isEligible = true
                        this.step = 'choice'
                    } else {
                        this.target.err = 'Désolé, ce domaine n\'est pas éligible.'
                    }
                })
                .catch(err => {
                    if (err.response) {
                        if (err.response.data) {
                            this.notify('Erreur', 'danger', err.response.data.detail)
                        } else {
                            this.notify('Erreur, Essayer de relancer la vérification', 'danger')
                        }
                    } else {
                        this.notify('Erreur, Essayer de relancer la vérification', 'danger')
                    }
                })
                .finally(() => {
                    this.is_loading = false
                })
            })
        },
        cancelStep(tostep) {
            this.step = tostep
            this.target.post = {}
        },
        toRetrieveArticle() {
            this.step = 'retrieve-settings'
            this.is_loading = true
            this.target.possiblePosts = []

            auth.currentUser.getIdToken(/* forceRefresh */ true)
                .then(idToken => {
                    this.$http.post(`${this.apiUrl}/wordpress/posts/getbyslug`, {
                    token: idToken,
                    domain: this.target.domain,
                    slug: this.target.slug,
                })
                .then(response => {
                    const posts = [...response.data.posts]
                    if (posts.length > 0) {
                        this.target.possiblePosts = posts
                        this.target.postsErr = ''
                    } else {
                        this.target.postsErr = 'L\'article n\'a pas été trouvé'
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.target.postsErr = 'Une erreur est survenu lors de la récupération de l\'article. L\'article n\'existe sans doute pas ou le wodpress n\'est finalement pas éligible'
                })
                .finally(() => {
                    this.is_loading = false
                })
            })
        },
        selectPost(post) {
            if (post.id === this.target.post.id) {
                this.target.post = {}
            } else {
                this.target.post = { ...post }
            }
        },
        extractPost() {
            // Réécriture / translation par Appel api vers un
            this.is_loading = true
            this.is_long_loading = true
            auth.currentUser.getIdToken(/* forceRefresh */ true)
            .then(idToken => {
                // Récupération du post
                this.$http.post(`${this.apiUrl}/post/convert`, {
                    title: this.target.post.title.rendered,
                    content: this.target.post.content.rendered,
                    token: idToken,
                })
                .then(response => {
                    this.finalPost.title = response.data.title
                    this.finalPost.content = response.data.content
                    this.step = 'editor'
                }, err => {
                    if (err.response) {
                        if (err.response.data) {
                            this.notify('Erreur', 'danger', err.response.data.detail)
                        } else {
                            this.notify('Erreur', 'danger', 'Réessayer ou changer d\'article')
                        }
                    } else {
                        this.notify('Erreur', 'danger', 'Réessayer ou changer d\'article')
                    }
                })
                .finally(() => {
                    this.is_loading = false
                    this.is_long_loading = false
                })
                // Récupération des catégories
                this.$http.post(`${this.apiUrl}/post/wp/taxonomies/get`, {
                    wpusername: this.blog.wpUserName,
                    wppassword: this.blog.wpPassword,
                    domain: this.blog.domain,
                    token: idToken,
                })
                .then(response => {
                    this.finalPost.possible_categories = response.data.categories
                    this.finalPost.possible_tags = response.data.tags
                    this.finalPost.possible_formats = response.data.formats
                    console.log(response)
                })
                .catch(err => {
                    if (err.response) {
                        if (err.response.data) {
                            this.notify('Erreur', 'danger', err.response.data.detail)
                        } else {
                            this.notify('Erreur', 'danger', 'Le WordPress ou l\'article doit être placé n\'accepte pas l\'insertion de l\'article. L\'identifiant ou le mot de passe n\'est sans doute plus valide, ou blogily s\'est fait bannir( veuillez attendre quelques minutes/heures ).')
                        }
                    } else {
                            this.notify('Erreur', 'danger', 'Le WordPress ou l\'article doit être placé n\'accepte pas l\'insertion de l\'article. L\'identifiant ou le mot de passe n\'est sans doute plus valide, ou blogily s\'est fait bannir( veuillez attendre quelques minutes/heures ).')
                        }
                })
                .finally(() => {
                    this.is_loading = false
                })
            })
        },
        addPost() {
            this.$swal({
                title: 'Voulez vous ajouter cet article ?',
                text: 'Cet article sera ajouté vos articles',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Oui ajouter l\'article',
                cancelButtonText: 'Annuler',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.is_loading = true

                    auth.currentUser.getIdToken(/* forceRefresh */ true)
                    .then(idToken => {
                        // Récupération du post
                        this.$http.post(`${this.apiUrl}/post/wp/insert`, {
                            title: this.finalPost.title,
                            content: this.finalPost.content,
                            wpusername: this.blog.wpUserName,
                            wppassword: this.blog.wpPassword,
                            domain: this.blog.domain,
                            status: this.finalPost.status,
                            categories: this.finalPost.categories,
                            tags: this.finalPost.tags,
                            formats: this.finalPost.formats,
                            keywords: this.finalPost.keyWords,
                            elementstype: this.finalPost.elementsType,
                        }, {
                            headers: {
                                Token: idToken,
                            },
                        })
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: 'Ajouté avec succès !',
                                text: 'Votre article a été ajouté avec succès !',
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                },
                            })
                            .then(() => {
                                // this.$router.push({
                                //     name: 'blogs',
                                // })
                            })
                        })
                        .catch(err => {
                            if (err.response.data.detail) {
                                this.notify('Erreur', 'danger', err.response.data.detail)
                            } else {
                                this.notify('Erreur', 'danger')
                            }
                        })
                        .finally(() => {
                            this.is_loading = false
                        })
                    })
                }
            })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
